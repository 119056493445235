<template>
    <div class="modal-overlay" @click="toggleBodyClass(false), $emit('close-modal')">
        <div style="margin: auto;max-width: 100%;">
            <div class="modal not-exist-products-modal" @click.stop>
                <div class="modal__close d-flex block-center transition" @click="toggleBodyClass(false), $emit('close-modal')">
                    <span class="icon-close transition"></span>
                </div>
                <div class="modal__inner d-flex flex-wrap">
                    <h2 class="modal__title">Смена меню</h2>
                    <span class="modal__text">
                        Меняется тип доставки или ресторан. Меню отличается от того что было и в нем нет товаров которые уже добавлены в корзине, а именно:
                    </span>

                    <div class="not-exist-products">
                        <div class="not-exist-product d-flex items-center"
                            v-for="(product, index) in notExistProducts"
                             :key="index"
                        >
                            <div class="not-exist-product__image d-flex block-center">
                                <img :src="`${ product.image }`" alt="product image" class="not-exist-product__image-img" v-if="product.image">
                                <img src="./../../assets/empty-image.svg" class="not-exist-product__image-img" alt="product image" v-else>
                            </div>

                            <div class="not-exist-product__info d-flex flex-column">
                                <span class="not-exist-product__info-title">{{ product.name }}</span>
                                <span class="not-exist-product__info-details d-flex flex-wrap">
                                    <span v-if="product.weight > 0">{{ parseFloat(product.weight * 1000) }} гр <span v-if="product.modifiers.length > 0">·&nbsp;</span></span>
                                    <span class="not-exist-product__info-modifier"
                                          v-for="(item, index) in product.modifiers"
                                          :key="index"
                                    >
                                        <span v-if="item.childrenSelected[0]">{{ getChildrenName(item) }}&nbsp;</span>
                                            <span v-else>{{ item.name }}.</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <span class="modal__text">
                        Продолжить переход на новое меню? При этом товары которых в новом меню нет будут удалены с корзины!!!
                    </span>

                    <div class="modal__buttons d-flex flex-wrap">
                        <button class="modal__buttons-btn modal__buttons-btn--yellow d-flex block-center transition"
                                @click="toggleBodyClass(false), $emit('close-modal'), deleteProducts()"
                        >
                            Удалить с корзины и продолжить
                        </button>
                        <button class="modal__buttons-btn modal__buttons-btn--grey d-flex block-center transition"
                                @click="toggleBodyClass(false), $emit('close-modal')"
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useOrderStore } from '@/store/order.js';
    export default {
        name: 'NotExistProductsModal',
        props: {
            notExistProducts: {
                type: Array,
                default: () => [],
            },
            newTypeDelivery: {
                type: String,
                default: () => '',
            },
        },
        data() {
            return {

            };
        },
        methods: {
            ...mapActions(useOrderStore, ['removeItem']),
            toggleBodyClass(val) {
                document.body.classList.toggle('hidden', val);
            },
            deleteProducts() {
                this.emitter.emit('loadingBlock', true);
                this.notExistProducts.forEach(item => {
                    this.removeItem(item);
                });
                this.$emit('close-modal');
                this.toggleBodyClass(false);
                if (this.newTypeDelivery === 'Самовывоз') {
                    this.emitter.emit('saveType');
                }
                if (this.newTypeDelivery === 'Доставка по адресу') {
                    this.emitter.emit('sendDeliveryPointType');
                }
                this.emitter.emit('loadingBlock', false);
                this.$router.push('/');
            },
            getChildrenName(item) {
                let children = item.children.filter(function (child) {
                    return item.childrenSelected.includes(child.id);
                });
                return children.map(child => child.name).join(', ');
            }
        },
    };
</script>
